<template>
  <base-layout page-title="Create Wi-Fi Access Point">
    <template v-slot:main-content>
      <div id="container">
        <div class="ion-padding">
          <p>This VEDA is automatically configured to connect to a Wi-Fi access point with the following details:</p>
          <p><b>SSID</b>:  {{ssid}}</p>
          <p><b>Password</b>: {{password}}</p>
          <p>802.11a/b/g/n</p>
          <p>2.4GHz</p>
          <p>Note. 5GHz is not supported.</p>
        </div>
        <ion-row>
          <ion-col>
            <ion-button @click="handleSkip" expand="block" fill="outline">SKIP</ion-button>
          </ion-col>
          <ion-col>
            <ion-button @click="createWifiAp" expand="block" fill="outline" color="primary">CONTINUE</ion-button>
          </ion-col>
        </ion-row>
      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from './BaseLayout.vue'
import { defineComponent } from 'vue'
import { loadingController } from "@ionic/vue"
import router from '../router'
import {userService} from "@/services/userService"
import {vedaService} from '@/services/vedaService'

// To test if a VEDA is connected to the internet, In other words, if the process is successful
// https://villageenergy.atlassian.net/browse/AD-704
// await fetch("https://cddevapi.village.energy/sat/v1/dvi/iot", {
//     "credentials": "include",
//     "headers": {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer {accessToken}"
//     },
//     "body": "{\"satAction\":\"pingDevice\",\"deviceId\":\"b8f009907af8\",\"returnRecentStatusMsg\":true,\"debug\":true}",
//     "method": "PUT",
//     "mode": "cors"
// })

export default defineComponent ({
  name: 'CreateWifiAP',
  components: {
    BaseLayout
  },
  data: () => {
    return {
      ssid: '',
      password: ''
    }
  },
  created: function() {
    const details = vedaService.getVedaDetails()
    this.ssid = details.device.wifiSsid || details.device.defaultWifiSsid
    this.password = details.device.wifiPassword || details.device.defaultWifiPassword
  },
  methods: {
    async createWifiAp () {
      const loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
                duration: 3000,
              })
      await loading.present()
      userService.createWifiAp({
        satAction: "pingDevice",
        deviceId: vedaService.getVedaDeviceId(),
        returnRecentStatusMsg: true,
        debug: vedaService.getDebug()
      }).then((response) => {
        console.log(response)
        if (response && response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
          router.push('/create-wifi-ap-success')
        } else {
          // alert('VEDA not connected to the internet')
          userService.openToast('VEDA not connected to the internet', 'danger')
        }
        loading.dismiss()
      }, (err) => {
        // alert('Sorry! Could not connect to the Wi-Fi ap')
        userService.openToast('Sorry! Could not connect to the Wi-Fi ap', 'warning')
        console.log(err)
        loading.dismiss()
      })
    },
    handleSkip() {
      router.push('/offline-mode')
    }
  }
})
</script>
